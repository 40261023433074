* {
  transition: all .5s;
}

.invisibleHr {
  clear: both;
  border: 1px solid transparent;
  color: transparent;
  height: 0px;
}

.boxshadow {
  box-shadow: 1px 2px 15px 0px rgba(0, 0, 0, 0.338);
}

/* HEADER */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 10px;
}

header h1 {
  font-family: 'SecularRegular';
  font-size: 30px;
  margin: 0px;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

nav a {
  color: #1A1A1A;
  text-decoration: none;
  font-size: 20px;
  font-family: 'SecularRegular';
  margin: 0px 30px 0px 30px;
}

nav a:hover {
  color: #03305A;
  border-bottom: 2px solid #03305A;
  transform: scale(1.2);
}

.buttonCV {
  background-color: #0b2c39;
  margin: 0px;
  border: none;
  width: 150px;
  color: #E7E7DD;
  text-decoration: none;
  font-size: 18px;
  font-family: 'SoraLight';
  text-align: center;
  padding: 10px;
  border-radius: 10px;
}

.buttonCV:hover {
  transform: scale(1.1);
  border: 1px solid #03305A;
  color: #03305A;
  background-color: white;
}

.containerIconsHeader {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.containerIconsHeader a{
  margin: 10px;
}

.containerIconsHeader a img{
  width: 30px;
  height: 30px;
}


@media (max-width: 1100px) {
  header h1 {
    font-family: 'SecularRegular';
    font-size: 25px;
  }

  nav a {
    font-size: 15px;
    margin: 0px 18px 0px 18px;
  }

  .buttonCV {
    font-size: 15px;
    padding: 8px;

  }
}

@media (max-width: 750px) {
  header {
    margin-bottom: 30px;
  }

  header h1 {
    font-size: 22px;
  }

  nav a {
    font-size: 13px;
    margin: 0px 18px 0px 18px;
  }

  .buttonCV {
    font-size: 13px;
    padding: 5px;
  }
}

@media (max-width: 680px) {
  header h1 {
    font-size: 20px;
  }

  nav a {
    font-size: 12px;
    margin: 0px 13px 0px 13px;
    letter-spacing: -.5px;
  }

  .buttonCV {
    width: 100px;
    font-size: 10px;
    padding: 5px;
  }

  .containerIconsHeader a img{
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 560px) {
  header {
    margin-bottom: 20px;
    width: 95%;
  }
}

@media (max-width: 480px) {
  header h1 {
    font-size: 15px;
  }

  nav a {
    font-size: 10px;
    margin: 0px 7px 0px 7px;
  }

  .buttonCV {
    font-size: 9px;
    width: 90px;
    padding: 2px;
  }
}

/* TYPING EFFECT */

.typewriter {
  background-color: #fff;
  width: 50%;
  padding: 10px;
  border: solid 1px #e6e6e6;
  border-radius: 2.8em;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
}

.typewriter p {
  color: #000;
  font-size: 1.5em;
  font-weight: 500;
  width: 0;
  max-width: max-content;
  overflow: hidden;
  border-right: .05em solid #464545;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0;
  /* Gives that scrolling effect as the typing happens */
}

.typing-erase {
  animation:
    4s typing-erase 8s steps(50, end) infinite,
    blink-caret .5s step-end infinite;
}

/* The type and erase effect */
@keyframes typing-erase {
  0% {
    width: 0
  }

  80% {
    width: 100%
  }

  90%,
  100% {
    width: 0
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: gray
  }
}

/* INTRODUCTION */

.introductionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.textIntroduction {
  max-width: 60%;
}

.textIntroduction h1 {
  color: #1A1A1A;
  text-decoration: none;
  font-size: 78px;
  font-family: 'SoraBold';
  text-align: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.textIntroduction p {
  color: #1A1A1A;
  text-decoration: none;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: 'SoraLight';
  text-align: left;
  margin: 0;
  padding: 0;
  padding-left: 20px;
}

.picIntroduction img {
  width: 450px;
  height: auto;
}

.skillsIntroduction {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  height: 70px;
  background-color: #F9F9F9;
  box-shadow: 1px 2px 15px 0px rgba(0, 0, 0, 0.338);
  border-radius: 50px;
  border: 1px solid rgb(202, 202, 202);
}

.skillsIntroduction div {
  width: 100%;
}

.skillsIntroduction h5 {
  background-color: #0b2c39;
  margin: 0px;
  width: 150px;
  color: #E7E7DD;
  text-decoration: none;
  font-size: 18px;
  font-family: 'SoraLight';
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.338);
}

.iconSkillsInt {
  background-color: #03305A;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.iconSkillsInt img {
  width: 35px;
}

.wBorderSkillsInt {
  border-right: 3px solid #D5D5D5;
  border-left: 3px solid #D5D5D5;
}

/* ABOUT ME */

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 56%
  }

  50% {
    background-position: 100% 45%
  }

  100% {
    background-position: 0% 56%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 56%
  }

  50% {
    background-position: 100% 45%
  }

  100% {
    background-position: 0% 56%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 56%
  }

  50% {
    background-position: 100% 45%
  }

  100% {
    background-position: 0% 56%
  }
}

.aboutMeContainer {
  background: linear-gradient(4deg, #0b2c39, #00597d, #1b7c82);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 4s ease infinite;
  -moz-animation: AnimationName 4s ease infinite;
  animation: AnimationName 4s ease infinite;


  position: relative;
  overflow: hidden;

  bottom: 40px;
  z-index: -1;
  padding-top: 50px;
}

.infoAboutMe h1 {
  color: #FDFDFD;
  width: 30%;
  text-decoration: none;
  font-size: 58px;
  letter-spacing: 2px;
  font-family: 'SoraBold';
  text-align: center;
  margin-top: 35px;
  border-bottom: 5px solid #FDFDFD;
}

.infoAboutMe div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 10px;
}

.infoAboutMe div img {
  width: 300px;
  height: auto;
  margin-right: 20px;
}

.infoAboutMe div p {
  margin-left: 20px;
  color: #FDFDFD;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: 'SoraLight';
  text-align: left;
}

.containerAboutMeSkills {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pieceSkill {
  width: 100%;
}

.imgAboutMeSkills {
  background-color: #FDFDFD;
  align-self: center;
  width: 80px;
  height: 80px;
  padding: 12px;
  border-radius: 50%;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.containerAboutMeSkills div div img {
  width: 50px;
  height: 50px;
}

.containerAboutMeSkills div h2 {
  width: 35%;
  color: #FDFDFD;
  font-family: 'SoraSemiBold';

  font-size: 38px;
  letter-spacing: -1px;
  text-align: center;
  border-bottom: 3px solid #FDFDFD;
}

.listSkills {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.listSkills h3 {
  background-color: #03305A;
  width: auto;
  color: #E7E7DD;
  text-decoration: none;
  font-size: 18px;
  font-family: 'SoraSemiBold';
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.338);
  margin: 5px;
}

/* MY WORK*/

.workContainer {
  width: 100%;
}

.workContainer h1 {
  color: #00597D;
  text-align: center;
  width: 30%;
  margin-left: 20px;
  text-decoration: none;
  font-size: 58px;
  letter-spacing: 2px;
  font-family: 'SoraBold';
  text-align: left;
  border-bottom: 5px solid #00597D;
}

.workContainer p {
  color: #222221;
  margin-left: 40px;
  text-decoration: none;
  font-size: 20px;
  font-family: 'SoraRegular';
  text-align: left;
}

.projectContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  width: 80%;
}

.picturesFirstProject {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: all .5s;
}

.pictureDesktop {
  width: 520px;
}

.pictureMobile {
  width: 200px;
}

.pictureTablet {
  margin-top: 10px;
  width: 300px;
}

.picturesFirstProject:hover {
  border: 1px rgba(128, 128, 128, 0.25) solid;
  transform: scale(.9);
  border-radius: 10px;
  filter: brightness(85%);
  background-color: rgba(0, 0, 0, 0.603);
  color: white;
}


.picturesFirstProject h2 {
  position: absolute;
  align-self: center;
  color: transparent;
  font-family: 'SoraBold';
  letter-spacing: 1px;
  font-size: 60px;
}

.infoFirstProject h2 {
  color: #00597D;
  margin-left: 40px;
  margin-top: 0;
  margin-bottom: 0;
  text-decoration: none;
  font-size: 48px;
  letter-spacing: 2px;
  font-family: 'SoraBold';
  text-align: left;

}

.skillsFirstProject {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
}

.skillsFirstProject h3 {
  background-color: #03305A;
  width: auto;
  color: #E7E7DD;
  text-decoration: none;
  font-size: 18px;
  font-family: 'SoraSemiBold';
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.338);
  margin: 5px;
}

/* OTHER PROJECTS */

@-webkit-keyframes OtherProjectBackground {
  0% {
    background-position: 0% 56%
  }

  50% {
    background-position: 100% 45%
  }

  100% {
    background-position: 0% 56%
  }
}

@-moz-keyframes OtherProjectBackground {
  0% {
    background-position: 0% 56%
  }

  50% {
    background-position: 100% 45%
  }

  100% {
    background-position: 0% 56%
  }
}

@keyframes OtherProjectBackground {
  0% {
    background-position: 0% 56%
  }

  50% {
    background-position: 100% 45%
  }

  100% {
    background-position: 0% 56%
  }
}

.otherProjectsContainer {
  display: flex;
  flex-direction: column;
}

.contOtherProTop {
  background-image: -webkit-linear-gradient(-93deg, white 50%, #008A93 50%);
  width: 100%;
  height: 100px;
}

.contOtherProBottom {
  background-image: -webkit-linear-gradient(93deg, #00597D 50%, #008A93 50%);
  width: 100%;
  height: 100px;
}

.containerInfoOtherProjects {
  background-color: #008A93;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerInfoOtherProjects h1 {
  color: white;
  text-decoration: none;
  font-size: 58px;
  letter-spacing: 2px;
  font-family: 'SoraBold';
  text-align: center;
  width: 50%;
  border-bottom: 4px solid white;
  margin-bottom: 20px;
}

.containerActualProjects {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.containerActualProjects>div {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 350px;
  height: 600px;
  background-color: white;
  border-radius: 20px;
  transition: all .5s;
}

.containerActualProjects>div:hover {
  transform: scale(1.05);
}

.containerActualProjects div h2 {
  color: black;
  font-size: 38px;
  font-family: 'SecularRegular';
  text-align: center;
  letter-spacing: -1px;
  margin: 0px;
  margin-top: 15px;
}

.imgOtherProjects {
  width: 100%;
  height: auto;
  margin: 0px;
  border-radius: 10px;
  transition: all .5s;
}

.containerActualProjects div p {
  color: #535353;
  width: 95%;
  letter-spacing: -1px;
  font-size: 15px;
  margin: 0px;
  margin-top: 10px;
  font-family: 'SoraSemiBold';
  text-align: center;
}

.containerActualProjects>div>img:hover {
  box-shadow: 1px 2px 15px 0px rgba(0, 0, 0, 0.338);
}

.containerActualProjects>div>button {
  background-color: white;
  padding: 2px;
  border-radius: 5px;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 28px;
  font-family: 'SoraBold';
  color: #025a7c;
  border: 2px solid #025a7c;
}

.containerIconSkillsOtherProjects {
  display: flex;
  justify-content: 'space-between';
  align-items: center;
  flex-direction: row;
}

.containerIconSkillsOtherProjects img {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.pictureCarrousel {
  width: 100%;
  height: auto;
  margin: 0px;
  margin-top: 15px;
  border-radius: 10px;
}

/* CONTACT */

.containerContact {
  background-color: #00597D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.containerContact h1 {
  color: white;
  text-decoration: none;
  font-size: 58px;
  letter-spacing: 2px;
  font-family: 'SoraBold';
  text-align: center;
  width: 50%;
  border-bottom: 4px solid white;
}

.containerInfoForm {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 80%;
}

.formContactContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.formContactContainer input {
  border: none;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 5px;

  font-family: 'SoraSemiBold';
  font-size: 16px;
}

.formContactContainer input:hover {
  border: 1px solid black;
}

.inputFirst {
  width: 500px;
  height: 50px;
}

.inputSecond {
  width: 1000px;
  height: 50px;
}

.inputThird {
  width: 1000px;
  height: 200px;
}

.socialMediaContainer .icon {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.socialMediaContainer .icon img {
  height: 80px;
  width: 80px;
}

.socialMediaContainer .icon img:hover {
  transform: translateY(-12px);
}

/* FOOTER */

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.footerContainer p {
  font-family: 'SecularRegular';
  font-size: 18px;
}

/* RESPONSIVE */

@media (max-width: 1700px) {
  .infoAboutMe h1 {
    width: 40%;
  }

  .infoFirstProject h2 {
    text-align: center;
  }

  .infoFirstProject p {
    text-align: center;
  }

  .projectContainerFirst {
    flex-wrap: wrap-reverse;
  }

  .projectContainerSecond {
    flex-wrap: wrap;
  }
}

@media (max-width: 1500px) {
  .inputFirst {
    width: 400px;
    height: 50px;
  }

  .inputSecond {
    width: 800px;
    height: 50px;
  }

  .inputThird {
    width: 800px;
    height: 200px;
  }

  .containerAboutMeSkills div h2 {
    width: 55%;

  }
}

@media (max-width: 1430px) {
  .containerInfoForm {
    flex-wrap: wrap;
  }

  .socialMediaContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .socialMediaContainer .icon img {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 1290px) {

  /* INTRODUCTION */
  .textIntroduction h1 {
    font-size: 60px;
  }

  .picIntroduction img {
    width: 400px;
    height: auto;
  }

  /* ABOUT ME */
  .infoAboutMe div {
    flex-direction: column;
  }

  .infoAboutMe div p {
    font-size: 18px;
    letter-spacing: 1px;
    margin-top: 20px;
  }

  .containerAboutMeSkills {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .pieceSkill {
    width: 50%;
    border: 1px solid transparent;
  }

  /* WORK */
  .workContainer h1 {
    text-align: center;
    width: 100%;
    border-bottom: none;
    margin-left: 0;
  }

  .workContainer p {
    text-align: center;
  }

  /* SKILLS */

  .skillsIntroduction h5 {
    font-size: 15px;
  }

  .iconSkillsInt {
    width: 35px;
    height: 35px;
  }

  .iconSkillsInt img {
    width: 32px;
  }
}

@media (max-width: 1065px) {

  /* INTRODUCTION */
  .textIntroduction h1 {
    font-size: 50px;
  }

  .picIntroduction img {
    width: 400px;
    height: auto;
  }

  .skillsIntroduction {
    height: 50px;
  }

  .skillsIntroduction h5 {
    width: 100px;
    padding: 8px;
  }

  /* SKILLS */
  .skillsIntroduction h5 {
    font-size: 12px;
  }

  .iconSkillsInt {
    width: 25px;
    height: 25px;
  }

  .iconSkillsInt img {
    width: 22px;
    height: auto;
  }

  /* CONTACT */
  .inputFirst {
    width: 300px;
    height: 50px;
  }

  .inputSecond {
    width: 600px;
    height: 50px;
  }

  .inputThird {
    width: 600px;
    height: 200px;
  }
}

@media (max-width: 890px) {

  /* INTRODUCTION */
  .textIntroduction h1 {
    font-size: 40px;
  }

  .textIntroduction p {
    font-size: 15px;
  }

  .picIntroduction img {
    width: 300px;
    height: auto;
  }

  /* CONTACT */
  .containerContact h1 {
    font-size: 40px;
  }

  .formContactContainer input {
    font-size: 13px;
  }

  .inputFirst {
    width: 230px;
  }

  .inputSecond {
    width: 470px;
  }

  .inputThird {
    width: 470px;
    height: 100px;
  }

  .socialMediaContainer .icon span {
    width: 60px;
    height: 60px;
  }

  /* ABOUT ME */
  .infoAboutMe h1 {
    font-size: 40px;
  }

  .infoAboutMe div img {
    width: 250px;
    height: auto;
    margin-right: 0px;
  }

  .infoAboutMe div p {
    text-align: center;
  }

  /* WORK */
  .pictureDesktop {
    width: 500px;
    margin: 5px;
  }

  .pictureMobile {
    width: 180px;
    margin: 5px;
  }

  .pictureTablet {
    margin: 5px;
    width: 200px;
  }

  /* SKILLS */
  .skillsIntroduction h5 {
    font-size: 9px;
    letter-spacing: 1px;
  }

  .iconSkillsInt {
    width: 20px;
    height: 20px;
  }

  .iconSkillsInt img {
    width: 18px;
    height: auto;
  }
}

@media (max-width: 750px) {

  /* INTRODUCTION */
  .textIntroduction h1 {
    font-size: 40px;
  }

  .textIntroduction p {
    font-size: 15px;
  }

  .picIntroduction img {
    width: 300px;
    height: auto;
  }

  .skillsIntroduction {
    height: 40px;
  }

  .skillsIntroduction h5 {
    width: 90px;
    padding: 6px;
    letter-spacing: .5px;
  }

  /* ABOUT ME */
  .infoAboutMe h1 {
    font-size: 40px;
  }

  .infoAboutMe div img {
    width: 250px;
    height: auto;
    margin-right: 0px;
  }

  .infoAboutMe div p {
    text-align: center;
  }

  /* WORK */
  .workContainer p {
    font-size: 15px;
    margin: 0;
  }

  .pictureDesktop {
    width: 320px;
  }

  .pictureMobile {
    width: 100px;
  }

  .pictureTablet {
    width: 150px;
  }

  /* CONTACT */
  .socialMediaContainer .icon img {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 650px) {

  /* INTRODUCTION */
  .textIntroduction h1 {
    font-size: 30px;
  }

  .textIntroduction p {
    font-size: 12px;
  }

  .picIntroduction img {
    width: 200px;
    height: auto;
  }

  .skillsIntroduction h5 {
    width: 80px;
    font-size: 8px;
  }

  .iconSkillsInt {
    padding: 15px;
  }

  .iconSkillsInt img {
    width: 15px;

  }

  /* ABOUT ME */
  .typewriter {
    width: 50%;
    padding: 10px;
  }

  .typewriter p {
    color: #000;
    font-size: 18px;
  }

  .infoAboutMe div {
    width: 70%;
  }

  .infoAboutMe h1 {
    font-size: 35px;
  }

  .infoAboutMe div img {
    width: 150px;
  }

  .infoAboutMe div p {
    font-size: 12px;
    text-align: center;
  }

  .imgAboutMeSkills {
    width: 50px;
    height: 50px;
  }

  .containerAboutMeSkills div div img {
    width: 50px;
    height: 50px;
  }

  .containerAboutMeSkills div h2 {
    font-size: 25px;
  }

  .listSkills {
    width: 100%;
  }

  .listSkills h3 {
    font-size: 13px;
    padding: 5px;
  }


  /* WORK */
  .workContainer h1 {
    font-size: 35px;
    margin: 0px;
    margin-bottom: 15px;
  }

  .workContainer p {
    font-size: 12px;
    margin: 0px;
    margin-bottom: 15px;
  }

  .infoFirstProject h2 {
    font-size: 30px;
    margin: 0px;
  }

  .infoFirstProject p {
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .skillsFirstProject h3 {
    font-size: 12px;
  }

  /* OTHER PROJECTS */

  .containerInfoOtherProjects h1 {
    font-size: 35px;
  }

  .containerActualProjects>div {
    width: 250px;
    height: auto;
  }

  .containerActualProjects>div h2 {
    font-size: 22px;
  }

  .containerActualProjects>div p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .containerActualProjects>div>button {
    font-size: 18px;
    margin-top: 0px;
    padding: 0px;
  }

  /* MODAL OTHER PROJECTS */

  .modTitleM {
    font-size: 10px;
    font-family: 'Sorabold';
  }

  /* CONTACT */
  .containerContact h1 {
    font-size: 35px;
  }

  .formContactContainer div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .inputFirst {
    width: 380px;
  }

  .inputSecond {
    width: 380px;
  }

  .inputThird {
    width: 380px;
  }

  .socialMediaContainer .icon img {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 530px) {

  /* INTRODUCTION */
  .typewriter p {
    font-size: 11px;
  }

  .textIntroduction h1 {
    font-size: 25px;
  }

  .textIntroduction p {
    font-size: 8px;
  }

  .picIntroduction img {
    width: 150px;
  }

  .pieceSkillInto h5 {
    font-size: 7px;
    padding: 5px;
    width: auto;
  }

  .skillsIntroduction {
    width: 80%;
    height: 30px;
  }

  .skillsIntroduction h5 {

    width: 80px;
    letter-spacing: -.5px;
    font-size: 8px;

    padding: 3px;
  }

  .pieceSkillInto {
    flex-direction: column;
    align-items: center;
    margin-top: 2px;
  }


  /* ABOUT ME */
  .infoAboutMe h1 {
    font-size: 20px;
    border-bottom: 2px solid #FDFDFD;

  }

  .infoAboutMe div img {
    width: 100px;
  }

  .infoAboutMe div p {
    font-size: 8px;
    letter-spacing: 0px;
  }

  .imgAboutMeSkills {
    width: 30px;
    height: 30px;
    padding: 8px;
  }

  .containerAboutMeSkills div div img {
    width: 30px;
    height: 30px;
  }

  .containerAboutMeSkills div h2 {
    width: 70%;
    font-size: 20px;
    border-bottom: 2px solid #FDFDFD;
  }

  .listSkills h3 {
    font-size: 8px;
  }


  /* WORK */

  .workContainer h1 {
    font-size: 20px;
    margin-bottom: 1px;
  }

  .workContainer p {
    font-size: 8px;
  }

  .infoFirstProject h2 {
    font-size: 17px;
  }

  .skillsFirstProject h3 {
    font-size: 8px;
    padding: 8px;
  }

  .pictureMobile {
    width: 50px;
    margin: 0px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .pictureDesktop {
    width: 200px;
    margin: 0px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .pictureTablet {
    width: 90px;
  }

  /* OTHER PROJECTS */
  .containerInfoOtherProjects h1 {
    font-size: 20px;
    border-bottom: 2px solid #FDFDFD;

  }

  .containerActualProjects>div {
    width: 200px;
  }

  .containerActualProjects>div>h2 {
    font-size: 18px;
    letter-spacing: -.5px;
  }

  .containerActualProjects>div>p {
    font-size: 10px;
  }

  .containerActualProjects>div>button {
    font-size: 12px;
  }

  .containerIconSkillsOtherProjects img {
    width: 17px;
    height: 17px;
    margin: 5px;
  }

  /* CONTACT */
  .containerContact h1 {
    font-size: 20px;
    border-bottom: 2px solid #FDFDFD;
  }

  .formContactContainer input {
    font-size: 10px;
    font-family: 'SoraLight';
  }

  .inputFirst {
    width: 100px;
    height: 30px;
  }

  .inputSecond {
    width: 220px;
    height: 30px;
  }

  .inputThird {
    width: 220px;
    height: 50px;
  }

  .socialMediaContainer .icon {
    margin-bottom: 10px;
  }

  .socialMediaContainer .icon img {
    width: 40px;
    height: 40px;
    margin-left: 15px;
    margin-right: 15px;

  }
}

@media (max-width: 456px) {

  /* INTRODUCTION */
  .textIntroduction {
    width: 50%;
  }

  .textIntroduction h1 {
    font-size: 20px;
  }

  .textIntroduction p {
    font-size: 8px;
    width: 100%;
  }

}