body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SoraBold";
  src: local("SoraBold"),
    url("./fonts/Sora/Sora-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SoraLight";
  src: local("SoraLight"),
    url("./fonts/Sora/Sora-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SoraSemiBold";
  src: local("SoraSemiBold"),
    url("./fonts/Sora/Sora-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SoraRegular";
  src: local("SoraRegular"),
    url("./fonts/Sora/Sora-Regular.ttf") format("truetype");
}

/* Secular */

@font-face {
  font-family: "SecularRegular";
  src: local("SecularRegular"),
    url("./fonts/Secular/SecularOne-Regular.ttf") format("truetype");
}